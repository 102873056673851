
/* paraphraph */
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Caveat&family=Corinthia:wght@400;700&family=Manrope&family=Alice&family=Amatic+SC:wght@400;700&family=Bellefair&family=Cinzel:wght@400..900&family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Libre+Caslon+Display&display=swap');
/* Corinthia
Cinzel
Bellefair
Manrope
Alice
Cormorant Infant
Libre Caslon Display */
/* buttons, subtitles */
@import url('https://fonts.googleapis.com/css2?family=Birthstone&family=Fleur+De+Leah&family=Imperial+Script&family=League+Script&family=Mea+Culpa&family=Rouge+Script&family=Tangerine:wght@400;700&display=swap');
/*
Birthstone
Fleur De Leah
Imperial Script
League Script
Mea Culpa
Rouge Script
Tangerine
*/

/* COLORS
#58ABAE dark teal
#A5CBC6 medium teal
#E5E5E5 light grey
#FAF4D0 olive
#FFD6D4 medium pink
#FEB8C5 dark pink
*/


html, body {
  color: #222;
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
  /* background-color: #A5CBC6; */
  z-index: 0;
  overscroll-behavior: none;
  background-image: url(./assets/darkmarble.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

a {
  cursor: pointer;
  text-decoration: none;
}

/* ABOUTME */
.aboutme {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 6%;
  background-size: cover;
  background-position: left;
}


.philosophy, .doula, .story {
  padding-right: 23%;
  padding-left: 2%;
  background-color: white;
  position: relative;
  box-shadow: 10px 10px 0px #FFD6D4;
  margin: auto;
  margin-right: 10%;
  width: 55%;
  margin-bottom: 2%;
}

.aboutme h1 {
  margin: 0;
  padding-top: 8%;
  padding-bottom: 3%;
  font-family: "Corinthia";
  text-align: center;
  color: #fff;
  color: #58ABAE;
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
}

.philosophy h1, .doula h1, .story h1 {
  padding: 0;
  padding-top: 5%;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  width: 100%;
  padding-bottom: 1%;
  margin-bottom: 0;
  color: black;
  font-weight: normal;
  font-family: "Amatic SC";
  font-size: 3.5em;
  text-align: center;
}

.philosophyContainer, .doulaContainer, .storyContainer{
  padding: 2.5%;
  padding-bottom: 5%;
  /* text-align: center; */
  column-count: 3;
  column-gap: 2%;
  font-family: 'Bad Script';
  width: 95%;
  display: block;
  /* border-left: 3px solid #FFD6D4; */
}

.philosophyContainer div, .doulaContainer div, .storyContainer div {
  line-height: 1.6;
}

.right {
  padding-left: 23%;
  padding-right: 2%;
}

.mobile {
  height: 0;
}

.philosophy img {
  position: absolute;
  width: 22%;
  left: 4%;
  bottom: 7%;
}
.doula img {
  position: absolute;
  right: 7%;
  bottom: 7%;
  width: 17%;
}
.story img {
  position: absolute;
  width: 25%;
  left: 3%;
  bottom: 20%;
}

/* END ABOUTME */

/* TESTIMONIALS */
.testimonialsContainer {
  padding: 0;
  margin: 0;
  padding-bottom:8%;
  margin-top: 1%;
  text-align: center;
}

.testimonialsContainer img {
 margin: auto;
 width: 20%;
 margin-top: 2%;
}

.testimonials {
display: flex;
transform: translateX(-100vw);
width: 300vw;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;
}

.testimonial {
  align-items: center;
  transform: translateX(0vw);
  position: relative;
  width: 27%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  margin-top: 1%;

}

.test {
  position: relative;
  width: 29%;
}

.testimonialsContainer div {
    pointer-events: none;
}

.move1 {
  animation: move1 2s forwards;
}

.move2 {
  animation: move2 2s forwards;
}

.moveback1 {
  animation: moveback1 2s forwards;
}

.moveback2 {
  animation: moveback2 2s forwards;
}

@keyframes move1 {
  0%{
    transform: translateX(0vw);
 }
 20%{
  transform: translateX(-100vw);
 }
 100% {
  transform: translateX(-100vw);
 }
}

@keyframes move2 {
  0%{
    transform: translateX(0vw);
 }
 20%{
  transform: translateX(-100vw);
 }
 100% {
  transform: translateX(-100vw);
 }
}

@keyframes moveback1 {
  0%{
    transform: translateX(0vw);
 }
 20%{
  transform: translateX(100vw);
 }
 100% {
  transform: translateX(100vw);
 }
}

@keyframes moveback2 {
  0%{
    transform: translateX(0vw);
 }
 20%{
  transform: translateX(100vw);
 }
 100% {
  transform: translateX(100vw);
 }
}


.quotation {
  transform: translate(-1.5vw, 1vh);
  display: block;
  text-align: left;
  font-weight: bold;
  width: 100%;
  line-height: 0;
  font-size: 4em;
  color: #FEB8C5;
}

.second {
  transform: translate(1vw, -1.5vh);
  width: 100%;
  text-align: right;
}

.quote {
  text-align: center;
  font-family: "Bad Script";
  color: #222;
  /* font-family: "Manrope"; */
  line-height: 1.8;
  font-size: 1.3em;
  width: 100%;
}

.customer {
  width: 100%;
  font-family: "Caveat";
  text-align: right;
  margin-top: 5%;
  margin-right: 5%;
  color: #222;
  font-size: 2em;
}

/* END TESTIMONIALS */

/* HOME */

.home {
  background-image: url(./assets/backgrounds/5.png);
  background-size: contain;
  height: 100vh;
  display: flex;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
}

.homeh1, .mobile1{
  text-shadow: 2px 2px 2px black;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 1%;
  margin: 0;
  font-family: "Corinthia";
  font-weight: bold;
  color: #FFD6D4;
  text-shadow: none;
  color: #58ABAE;
  /* color: #FEB8C5; */
  /* color: #A5CBC6; */
  font-size: 14em;
}

.mobile1 {
  opacity: 0;
}
/* END HOME */

/* NAV */
.nav {
  z-index: 100;
  top: 0;
  padding-left: 5%;
  padding-right: 5%;
  width: 90%;
  height: 10vh;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.thin {
  animation: thin .5s ease forwards;
}

.wide {
  width: 100%;
  padding: 0;
  position: fixed;
  height: 5vh;
  animation: wide .5s ease forwards;
}

@keyframes wide {
  0% {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
  100% {
    padding: 0;
    width: 100%;
  }
}

@keyframes thin {
  0% {
    padding: 0;
    width: 100%;
  }
  100% {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
}

.nav div{
  height: 50%;
  background-color: #FFD6D4;
  box-shadow: 2px 2px 2px black;
 width: 75%;
 margin-left: auto;
 display: flex;
 align-items: center;
 justify-content: space-around;
}

.thin div {
  animation: thindiv 1s ease forwards;
}

.wide div {
  padding-left: 20%;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  animation: widediv 1s ease forwards;
}

@keyframes widediv {
  0% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

@keyframes thindiv {
  0% {
    width: 100%;
  }
  100% {
    width: 75%;
  }
}

.icon {
  height: 0;
}

.wide .icon {
  position: fixed;
  height: 10vh;
  padding: 0;
  margin: 0;
  bottom: 5vh;
  right: 5vh;
}


.nav div a {
  /* font-weight: bold; */
  font-family: "Bellefair";
  font-size: 1em;
}

.nav a {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #222;
  /* background-color: #023047; */
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}


.nav a:hover{
  opacity: .25;
}

.logo{
  padding: 0;
  margin: 0;
  left: 4.5%;
  top: 0;
  height: 7vh;
  display: flex;
  align-items: center;
  font-family: "Amatic SC";
  font-size: 4em;
  position: fixed;
}

.thin .logo {
  animation: thinlogo .5s ease forwards;
}

.wide .logo {
  margin-left: 10%;
  animation: widelogo .5s ease forwards;
}

@keyframes widelogo {
  0% {
    height: 7vh;
    margin-left: 0%;
    font-size: 4em;
  }
  100% {
    height: 2.5vh;
    font-size: 2em;
    margin-left: 5%;
  }
}

@keyframes thinlogo {
  0% {
    height: 2.5vh;
    font-size: 2em;
    margin-left: 5%;
  }
  100% {
    height: 7vh;
    margin-left: 0%;
    font-size: 4em;
  }
}
/* END NAV */

/* FOOTER */
.footer {
  font-weight: bold;
  color: #fff;
  /* color: #444; */
  /* background-color: #FFD6D4; */
  /* background-color: #58ABAE; */
  font-family: "Manrope";
  font-family: "Bellefair";
  font-weight: lighter;
  width: 60%;
  display: flex;
  justify-content: space-around;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 5%;
  padding-bottom: 4%;
}

.footerContainer {
  background-color: #A5CBC6;
}

.locationp {
  /* width: 50%; */
  font-family: "Bellefair";
  font-weight: normal;
  /* color: white; */
  color: black;
  text-align: center;
  padding: 0;
  margin: auto;
  /* padding-bottom: 7%; */
}

.footercol h1{
  width: 200%;
  color: #58ABAE;
  color: #FFD6D4;
  font-family: "Amatic SC";
}
.footercol div {
  font-size: 1.3em;
}
.footercol a{
  text-decoration: none;
  color: #444;
  color: white;
  -webkit-transition: opacity .1s;
  -moz-transition: opacity .1s;
  -o-transition: opacity .1s;
  transition: opacity .1s;
}


.social img {
  height: 3vh;
  padding-right: 15%;
  -webkit-transition: opacity .1s;
  -moz-transition: opacity .1s;
  -o-transition: opacity .1s;
  transition: opacity .1s;
}

.footer a:hover {
  cursor: pointer;
  opacity: .5;
}

.createdby {
  width: 94%;
  text-align: center;
  padding: 3%;
  font-family: "Bellefair";
  color:white;
  padding-bottom: 5%;
}

.createdby a{
  padding: 1px;
  text-decoration: none;
  /* background-color: #521814; */
  font-weight: bold;
  color:#FFD6D4;
  -webkit-transition: background-color .25s;
  -moz-transition: background-color .25s;
  -o-transition: background-color .25s;
  transition: background-color .25s;
}

.createdby a:hover {
  background-color:#FFD6D4;
  color: black;
  font-weight: normal;
}
/* END FOOTER */

/* SERVICES */
.services {
  /* background-image: url(./assets/darkmarble.jpg); */
  margin: 0;
  padding: 0;
  margin-bottom: 5%;
  /* background-color: white; */
  /* margin: 10%; */
}

.servicesContainer {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.services h2 {
  font-family: "Amatic SC";
  /* font-weight: normal; */
  margin: 0;
  text-align: center;
  /* margin-left: 10%; */
  margin-top: 12%;
  margin-bottom: 6%;
}

.servicesh1 {
  margin: 0;
  padding-top: 8%;
  padding-bottom: 3%;
  margin: 0;
  padding-top: 8%;
  padding-bottom: 3%;
  /* font-weight: normal; */
  font-family: "Corinthia";
  text-align: center;
  /* color: #fff; */
  color: #58ABAE;
  font-family: "Corinthia";
  text-align: center;
  /* color: #fff; */
  color: #58ABAE;
}


.servicesContainer .item:nth-of-type(1n){
  background-color: #fff;
}

.servicesContainer .item:nth-of-type(1n) li::marker{
  /* color: #58ABAE; */
  color: #FEB8C5;
}


.services .item {
  width: 31%;
  box-shadow: 10px 10px 0px #FFD6D4;
}

.services p {
  margin-top: 5%;
  /* font-family: "Manrope"; */
  text-align: center;
  font-family: "Bad Script";
  /* font-weight: bold; */
}

.services p span {
  /* color: #FEB8C5; */
  color: #58ABAE;
  font-weight: bold;
}

.services ul {
  list-style: '✓';
  margin: 10%;
  padding-left: 0%;
  margin-top: 0%;
  font-family: "Bad Script";
  text-align: left;
  /* line-height: 1.5; */
}


li {
  margin-bottom: 1%;
  padding-left: 3%;
}

/* END SERVICES */

/* CONTACTME */
.contactme {
  height: 90vh;
  width: 80%;
  margin-left: 10%;
  padding-top: 10vh;
  /* padding-top: 5%;
  padding-bottom: 5%; */
  position: relative;
  /* background-image: url(./assets/marble.jpg); */
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* overflow: hidden; */
}
.mobileimg, .desktopimg {
  height: 85vh;
  margin-top: 5vh;
}

.formContainer {
  padding: 3%;
  width: 35vw;
  /* background-color: #fff; */
  /* box-shadow: 15px 15px 0px #58ABAE; */
}
.formContainer h1 {
  color: #58ABAE;
  /* font-weight: bold; */
  /* text-align: center; */
  /* font-weight: normal; */
  text-align: center;
  font-family: "Corinthia";
  padding-left: 1%;
  font-size: 5.5em;
  margin: 0;
}
.formItem {
  width: 100%;
}

.contacticons {
  padding-top: 10%;
  width: 50%;
  /* background-color: #023047; */
  display: flex;
  justify-content: space-around;
  margin: auto;
}

.contacticons img{
  height: 4vh;
}
/* END CONTACTME */

/* WOULD SUBTLE SHADOWS BE BETTER */
@media screen and (orientation:portrait) {

  .home {
    background-image: url(./assets/backgrounds/5mobile.png);
    background-size: cover;

  }
  .homeh1 {
    margin-top: 70%;
    line-height: .9;
    opacity: 0;
  }

  .mobile1 {
    opacity: 1;
    margin-top: 45%;
    font-weight: 400;
    text-shadow: none;
    color: #58ABAE;
    bottom: 0;
  }


  .nav div{
    margin-top: 10%;
   width: 100%;
  }

  .thin div {
    animation: thindiv .5s ease forwards;
  }

  .wide div {
    animation: widediv .5s ease forwards;
  }

  @keyframes widediv {
    0% {
      margin-top: 10%;
      width: 100%;
    }
    100% {
      margin-top: 0%;
      width: 100%;
    }
  }

  @keyframes thindiv {
    0% {
      margin-top: 0%;
      width: 100%;
    }
    100% {
      margin-top: 10%;
      width: 100%;
    }
  }


  .logo{
    /* top: 8%; */
    /* left: 30%; */
    height: 2.75vh;
    text-align: center;
    font-size: 3em;
    left: 50%;
    transform: translateX(-50%);
  }

  .thin .logo {
    animation: thinlogo .5s ease forwards;
  }

  .wide .logo {
    animation: widelogo .5s ease forwards;
  }

  @keyframes widelogo {
    0% {
      left: 50%;
      transform: translateX(-50%);
      font-size: 3em;
      height: 2.75vh;
    }
    100% {
      font-size: 2em;
      transform: none;
      left: -6%;
      height: 2.25vh;
    }
  }

  @keyframes thinlogo {
    0% {
      font-size: 2em;
      transform: none;
      left: -6%;
      height: 2.25vh;
    }
    100% {
      left: 50%;
      transform: translateX(-50%);
      font-size: 3em;
      height: 2.75vh;
    }
  }

  .philosophy, .doula, .story {
    text-align: left;
    width: 80%;
    margin: 0;
    padding: 5%;
    /* background-color: #023047; */
  /* margin-top: 5%; */
  margin-left: 5%;
  margin-right: 5%;
  /* padding: 10%; */
  }
  .philosophy img, .doula img, .story img {
    height: 0;
  }

  .aboutme {
    text-align: center;
    padding-bottom: 5%;
  }

  .philosophyContainer, .doulaContainer, .storyContainer{
    padding: 2.5%;
    column-count: 1;
  }

  .aboutme h1 {
    padding: 0;
    margin: auto;
    padding-top: 27%;
    padding-bottom: 7%;
  }

  .story h1, .philosophy h1, .doula h1 {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .mobile {
    height: 25vh;
    margin-top: 10%;
    margin-bottom: 8%;
  }

  .servicesContainer {
    flex-direction: column;
  }

  .servicesContainer .item {
    width: 100%;
    margin-bottom: 15%;
  }

.servicesh1 {
  padding: 0;
  margin: auto;
  padding-top: 27%;
  padding-bottom: 7%;
  /* background-color: #023047; */
}

  .services h2 {
    margin-top: 10%;
    margin-bottom: 6%;
  }

  .services p {
    margin-top: 2%;
    margin-bottom: 20%;
  }

  .testimonial {
    margin-top: 3%;
    padding-top: 1vw ;
  }

  .quotation {
    transform: translate(-4vw, 1vh);
  }

  .second {
    transform: translate(3.5vw, -1vh);
  }


  .contactme {
    text-align: center;
    display: block;
    height: auto;
    overflow: hidden;
  }

  .contactme .formContainer {
    border-radius: 25px;
    margin-top: 20%;
    margin-bottom: 5%;
    width: 94%;
  }

  .mobileimg, .desktopimg {
    position: relative;
    height: 65vh;
    margin-left: 6%;
    margin-top: 15%;
    margin-bottom: -7%;
  }


  .footer {
    width: 80%;
    padding-left: 10%;
    /* padding-bottom: 20%; */
  }

  .locationp {
    width: 50%;
    padding-bottom: 3%;
  }


  .footer .social img {
    height: 2vh;
  }

  .createdby {

  padding-bottom: 10%;
  }



  .testimonialsContainer img {
    margin: auto;
    width: 40%;
    top: 0%;
    margin-bottom: -5%;
   }

   .contacticons{
    padding-top: 20%;
    width: 60%;
  }

   .contacticons img{
    height: 3.5vh;
  }
}